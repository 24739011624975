<template>
  <v-container id="dashboard" fluid tag="section">
    <v-flex xs12 sm12 md12>
      <material-card>
        <template v-slot:heading>
          <div class="display-2 font-weight-light">Close Inbound</div>
        </template>
        <v-row row>
          <v-col md="2" xs="12"> Search/Busqueda </v-col>
          <v-col md="2" xs="12"> Company/Compania </v-col>
          <v-col md="2" xs="12"> Checkin Date/Fecha Checkin</v-col>
        </v-row>
        <v-row row>
          <v-col md="2" xs="12">
            <v-text-field
              solo
              class="right-input"
              v-model="form.search"
              maxlength="13"
              placeholder="Search order:"
              append-icon="fas fa-search"
            ></v-text-field>
          </v-col>
          <v-col md="2" xs="12">
            <v-autocomplete
              solo
              :items="controls.companies"
              item-text="companyName"
              item-value="idCompany"
              v-model="form.idCompany"
            ></v-autocomplete>
          </v-col>
          <v-col md="2" xs="12">
            <date-range-picker
              solo
              ref="picker"
              :opens="controls.datepicker.opens"
              :minDate="controls.datepicker.minDate"
              :maxDate="controls.datepicker.maxDate"
              :singleDatePicker="controls.datepicker.singleDatePicker"
              :timePicker="controls.datepicker.timePicker"
              :showWeekNumbers="controls.datepicker.showWeekNumbers"
              :showDropdowns="controls.datepicker.showDropdowns"
              :autoApply="controls.datepicker.autoApply"
              v-model="controls.datepicker.dateRange"
              :ranges="controls.datepicker.show_ranges ? undefined : false"
              @update="updateValues"
              @toggle="checkOpen"
              :linkedCalendars="controls.datepicker.linkedCalendars"
              :dateFormat="dateFormat"
            >
              <div slot="input" slot-scope="picker" style="min-width: 150px">
                <div
                  class="
                    v-input
                    theme--light
                    v-text-field
                    v-text-field--single-line
                    v-text-field--solo
                    v-text-field--is-booted
                    v-text-field--enclosed
                    v-select v-autocomplete
                  "
                >
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        {{ picker.startDate | date }} -
                        {{ picker.endDate | date }}
                      </div>
                      <div class="v-input__append-inner">
                        <div class="v-input__icon v-input__icon--append">
                          <v-icon right>mdi-calendar-range</v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <template slot="calendar-icon">
                <icon>mdi-calendar</icon>
              </template>
            </date-range-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" xs="12">
            <v-btn color="green" dark @click="fetchData" :loading="isLoading">
              Find/Buscar
              <v-icon right>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="blue"
              dark
              @click="download"
              :loading="isLoading"
              style="margin-left: 5px !important"
            >
              Export/Exportar
              <v-icon right>mdi-download</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-layout row wrap>
          <v-flex md12 sm12>
            <material-card
              icon="mdi-location-enter"
              title="Close Inbound"
              color="blue darken-1"
            >
              <v-data-table
                id="inbound-close"
                :headers="inbound.close.headers"
                :items="inbound.close.data"
                :options.sync="inbound.close.options"
                :server-items-length="inbound.close.totalItems"
                :loading="inbound.close.isLoading"
                :key="inbound.close.data.length"
                item-key="po"
                class="elevation-1 inbound-pending"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.grower }}</td>
                  <td>{{ props.item.po }}</td>
                  <td>{{ props.item.companyName }}</td>
                  <td>{{ props.item.checkInDate }}</td>
                  <td>{{ props.item.doorName }}</td>
                  <td>{{ props.item.statusDesc }}</td>
                  <td>
                    <div
                      class="color"
                      :class="props.item.efficiencyColor"
                    ></div>
                  </td>
                </template>
                <template
                  v-slot:[`item.actions`]="{ item, index }"
                  class="justify-center layout px-0"
                >
                  <v-icon
                    small
                    class="mr-2"
                    @click="goInbound(item)"
                    title="Edit/Editar"
                    >mdi-more</v-icon
                  >
                </template>

                <template v-slot:[`item.closeAt`]="{ item }">
                  {{ $moment(item.closeAt).format("YYYY/MM/DD") }}
                </template>
                <template v-slot:[`item.unloadedPallets`]="{ item }">
                  <div class="d-flex justify-end">
                    ${{ item.unloadedPallets.toFixed(2) }}
                  </div>
                </template>
                <template v-slot:[`item.palletsRestack`]="{ item }">
                  <div class="d-flex justify-end">
                    ${{ item.palletsRestack.toFixed(2) }}
                  </div>
                </template>
                <template v-slot:[`item.qcInspection`]="{ item }">
                  <div class="d-flex justify-end">${{ item.qcInspection }}</div>
                </template>
                <template v-slot:[`item.damagedBoxes`]="{ item }">
                  <div class="d-flex justify-end">${{ item.damagedBoxes }}</div>
                </template>
                <template v-slot:[`item.damagedPallets`]="{ item }">
                  <div class="d-flex justify-end">
                    ${{ item.damagedPallets }}
                  </div>
                </template>

                <template v-slot:[`item.inbPalletsIn`]="{ item }">
                  <div class="d-flex justify-end">${{ item.inbPalletsIn }}</div>
                </template>
                <template v-slot:[`item.inbPTI`]="{ item }">
                  <div class="d-flex justify-end">${{ item.inbPTI }}</div>
                </template>
                <template v-slot:[`item.inbRepackIn`]="{ item }">
                  <div class="d-flex justify-end">${{ item.inbRepackIn }}</div>
                </template>
                <template v-slot:[`item.inbPalletReplacement`]="{ item }">
                  <div class="d-flex justify-end">
                    ${{ item.inbPalletReplacement }}
                  </div>
                </template>
                <template v-slot:[`item.inbOvertime`]="{ item }">
                  <div class="d-flex justify-end">${{ item.inbOvertime }}</div>
                </template>
                <template v-slot:[`item.inbSundayOt`]="{ item }">
                  <div class="d-flex justify-end">${{ item.inbSundayOt }}</div>
                </template>
                <template v-slot:[`item.inbCucumberRepack`]="{ item }">
                  <div class="d-flex justify-end">
                    ${{ item.inbCucumberRepack }}
                  </div>
                </template>
                <template v-slot:[`item.inbPLURepack`]="{ item }">
                  <div class="d-flex justify-end">${{ item.inbPLURepack }}</div>
                </template>
                <template v-slot:[`item.inbSquashRepack`]="{ item }">
                  <div class="d-flex justify-end">
                    ${{ item.inbSquashRepack }}
                  </div>
                </template>
                <template v-slot:[`item.inbTomatoRepack`]="{ item }">
                  <div class="d-flex justify-end">
                    ${{ item.inbTomatoRepack }}
                  </div>
                </template>
                <template slot="pageText" slot-scope="props"
                  >Lines {{ props.pageStart }} - {{ props.pageStop }} de
                  {{ props.itemsLength }}</template
                >
                <template slot="body.append">
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.unloadedPalletsQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.unloadedPallets.toFixed(2) }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.palletsRestackQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.palletsRestack }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.qcInspectionQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.qcInspection }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.damagedBoxesQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.damagedBoxes }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.damagedPalletsQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.damagedPallets }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.inbPalletsInQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.inbPalletsIn }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.inbPTIQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.inbPTI }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.inbRepackInQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.inbRepackIn }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.inbPalletReplacementQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.inbPalletReplacement }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.inbOvertimeQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.inbOvertime }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.inbSundayOtQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.inbSundayOt }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.inbCucumberRepackQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.inbCucumberRepack }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.inbPLURepackQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.inbPLURepack }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.inbSquashRepackQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.inbSquashRepack }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        {{ inbound.close.totals.inbTomatoRepackQty }}
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-end">
                        ${{ inbound.close.totals.inbTomatoRepack }}
                      </div>
                    </th>
                  </tr>
                </template>
              </v-data-table>
            </material-card>
          </v-flex>
        </v-layout>
      </material-card>
    </v-flex>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import companies from "@/services/api/companies";
import inbound from "@//services/api/inbound";

export default {
  name: "CloseReport",
  filters: {
    date(value) {
      if (!value) return "";
      return moment(value).format("YYYY-MM-DD");
    },
  },
  data() {
    return {
      form: {
        search: null,
        idCompany: null,
        date: {},
      },
      controls: {
        companies: [],
        searchForm: null,
        datepicker: {
          opens: "center",
          minDate: "2021-06-01",
          maxDate: moment().add(1, "days").format("YYYY-MM-DD"),
          dateRange: {
            startDate: moment().startOf("month").format("YYYY-MM-DD"),
            endDate: moment().add(1, "days").format("YYYY-MM-DD"),
          },
          show_ranges: true,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: false,
          showDropdowns: true,
          autoApply: false,
          showWeekNumbers: true,
          linkedCalendars: true,
        },
      },
      inbound: {
        close: {
          headers: [
            {
              text: "Vendor",
              align: "left",
              sortable: true,
              value: "grower",
            },
            {
              text: "PO",
              align: "left",
              sortable: true,
              value: "po",
            },
            {
              text: "Close",
              align: "left",
              sortable: true,
              value: "closeAt",
            },
            {
              text: "Company",
              align: "left",
              sortable: true,
              value: "companyName",
            },
            {
              text: "Unloaded Pallets Qty",
              align: "right",
              sortable: false,
              value: "unloadedPalletsQty",
            },
            {
              text: "Unloaded Pallets",
              align: "right",
              sortable: false,
              value: "unloadedPallets",
            },
            {
              text: "Pallets Restack Qty",
              align: "right",
              sortable: false,
              value: "palletsRestackQty",
            },
            {
              text: "Pallets Restack",
              align: "right",
              sortable: false,
              value: "palletsRestack",
            },
            {
              text: "QC Inspection Qty",
              align: "right",
              sortable: false,
              value: "qcInspectionQty",
            },
            {
              text: "QC Inspection",
              align: "right",
              sortable: false,
              value: "qcInspection",
            },
            {
              text: "Damaged Boxes Qty",
              align: "right",
              sortable: false,
              value: "damagedBoxesQty",
            },
            {
              text: "Damaged Boxes",
              align: "right",
              sortable: false,
              value: "damagedBoxes",
            },
            {
              text: "Damages Pallets Qty",
              align: "right",
              sortable: false,
              value: "damagedPalletsQty",
            },
            {
              text: "Damages Pallets",
              align: "right",
              sortable: false,
              value: "damagedPallets",
            },
            {
              text: "Pallets In Qty",
              align: "right",
              sortable: false,
              value: "inbPalletsInQty",
            },
            {
              text: "Pallets In",
              align: "right",
              sortable: false,
              value: "inbPalletsIn",
            },
            {
              text: "PTI Qty",
              align: "right",
              sortable: false,
              value: "inbPTIQty",
            },
            {
              text: "PTI",
              align: "right",
              sortable: false,
              value: "inbPTI",
            },
            {
              text: "RepackIn Qty",
              align: "right",
              sortable: false,
              value: "inbRepackInQty",
            },
            {
              text: "RepackIn",
              align: "right",
              sortable: false,
              value: "inbRepackIn",
            },
            {
              text: "Pallet Replacement Qty",
              align: "right",
              sortable: false,
              value: "inbPalletReplacementQty",
            },
            {
              text: "Pallet Replacement",
              align: "right",
              sortable: false,
              value: "inbPalletReplacement",
            },
            {
              text: "Overtime Qty",
              align: "right",
              sortable: false,
              value: "inbOvertimeQty",
            },
            {
              text: "Overtime",
              align: "right",
              sortable: false,
              value: "inbOvertime",
            },
            {
              text: "Sunday OT Qty",
              align: "right",
              sortable: false,
              value: "inbSundayOtQty",
            },
            {
              text: "Sunday OT",
              align: "right",
              sortable: false,
              value: "inbSundayOt",
            },
            {
              text: "Cucumber Repack Qty",
              align: "right",
              sortable: false,
              value: "inbCucumberRepackQty",
            },
            {
              text: "Cucumber Repack",
              align: "right",
              sortable: false,
              value: "inbCucumberRepack",
            },
            {
              text: "PLU Repack Qty",
              align: "right",
              sortable: false,
              value: "inbPLURepackQty",
            },
            {
              text: "PLU Repack",
              align: "right",
              sortable: false,
              value: "inbPLURepack",
            },
            {
              text: "Squash Repack Qty",
              align: "right",
              sortable: false,
              value: "inbSquashRepackQty",
            },
            {
              text: "Squash Repack",
              align: "right",
              sortable: false,
              value: "inbSquashRepack",
            },
            {
              text: "Tomato Repack Qty",
              align: "right",
              sortable: false,
              value: "inbTomatoRepackQty",
            },
            {
              text: "Tomato Repack",
              align: "right",
              sortable: false,
              value: "inbTomatoRepack",
            },
          ],
          data: [],
          pagination: {
            filters: "",
            bActivePaged: true,
            page: 1,
            itemsPerPage: 15,
            sortBy: "",
            sortDesc: false,
          },
          totals: {
            unloadedPallets: 0,
            palletsRestack: 0,
            qcInspection: 0,
            damagedBoxes: 0,
            damagedPallets: 0,
            inbPalletsIn: 0,
            inbPTI: 0,
            inbRepackIn: 0,
            inbPalletReplacement: 0,
            inbOvertime: 0,
            inbSundayOt: 0,
            inbCucumberRepack: 0,
            inbPLURepack: 0,
            inbSquashRepack: 0,
            inbTomatoRepack: 0,

            unloadedPalletsQty: 0,
            palletsRestackQty: 0,
            qcInspectionQty: 0,
            damagedBoxesQty: 0,
            damagedPalletsQty: 0,
            inbPalletsInQty: 0,
            inbPTIQty: 0,
            inbRepackInQty: 0,
            inbPalletReplacementQty: 0,
            inbOvertimeQty: 0,
            inbSundayOtQty: 0,
            inbCucumberRepackQty: 0,
            inbPLURepackQty: 0,
            inbSquashRepackQty: 0,
            inbTomatoRepackQty: 0,
          },
          options: {},
          totalItems: 0,
          isLoading: false,
        },
      },
    };
  },
  components: {
    MaterialCard: () => import("@/components/base/MaterialCard"),
  },
  created() {
    this.getAllCompanies("");
    this.updateValues(this.controls.datepicker.dateRange);
  },
  beforeDestroy() {},
  watch: {
    "inbound.close.options": {
      handler() {
        this.getInboundClose(false);
      },
      deep: true,
    },
    search: {
      handler() {
        this.getInboundClose(false);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    async fetchData() {
      this.getInboundClose();
    },
    async getInboundClose() {
      this.inbound.close.isLoading = true;
      this.setLoading(true);
      try {
        this.inbound.close.pagination.filters = this.form.search;
        this.inbound.close.pagination.idCompany = this.form.idCompany;
        this.inbound.close.pagination.startDate = this.form.date.startDate;
        this.inbound.close.pagination.endDate = this.form.date.endDate;
        this.inbound.close.pagination.bActivePaged = true;
        this.inbound.close.pagination.page = this.inbound.close.options.page;
        this.inbound.close.pagination.itemsPerPage =
          this.inbound.close.options.itemsPerPage;
        if (this.inbound.close.options.sortBy) {
          this.inbound.close.pagination.sortBy =
            this.inbound.close.options.sortBy[0];
        }
        if (this.inbound.close.options.sortDesc) {
          this.inbound.close.pagination.sortDesc =
            this.inbound.close.options.sortDesc[0];
        }

        this.controls.searchForm = this.inbound.close.pagination;
        let OData = Object.assign({}, this.inbound.close.pagination);
        OData.qaFormat = JSON.stringify(this.inbound.close.pagination.qaFormat);

        const response = await inbound.calculationClosePaged(OData);
        this.inbound.close.data = response.body.data;
        this.inbound.close.totalItems = response.body.totalItems;

        this.inbound.close.totals.unloadedPallets = 0;
        this.inbound.close.totals.palletsRestack = 0;
        this.inbound.close.totals.qcInspection = 0;
        this.inbound.close.totals.damagedBoxes = 0;
        this.inbound.close.totals.damagedPallets = 0;
        this.inbound.close.totals.unloadedPalletsQty = 0;
        this.inbound.close.totals.palletsRestackQty = 0;
        this.inbound.close.totals.qcInspectionQty = 0;
        this.inbound.close.totals.damagedBoxesQty = 0;
        this.inbound.close.totals.damagedPalletsQty = 0;

        this.inbound.close.totals.inbPalletsIn = 0;
        this.inbound.close.totals.inbPTI = 0;
        this.inbound.close.totals.inbRepackIn = 0;
        this.inbound.close.totals.inbPalletReplacement = 0;
        this.inbound.close.totals.inbOvertime = 0;
        this.inbound.close.totals.inbSundayOt = 0;
        this.inbound.close.totals.inbCucumberRepack = 0;
        this.inbound.close.totals.inbPLURepack = 0;
        this.inbound.close.totals.inbSquashRepack = 0;
        this.inbound.close.totals.inbTomatoRepack = 0;

        this.inbound.close.totals.inbPalletsInQty = 0;
        this.inbound.close.totals.inbPTIQty = 0;
        this.inbound.close.totals.inbRepackInQty = 0;
        this.inbound.close.totals.inbPalletReplacementQty = 0;
        this.inbound.close.totals.inbOvertimeQty = 0;
        this.inbound.close.totals.inbSundayOtQty = 0;
        this.inbound.close.totals.inbCucumberRepackQty = 0;
        this.inbound.close.totals.inbPLURepackQty = 0;
        this.inbound.close.totals.inbSquashRepackQty = 0;
        this.inbound.close.totals.inbTomatoRepackQty = 0;

        this.inbound.close.data.forEach((i) => {
          this.inbound.close.totals.unloadedPallets += i.unloadedPallets;
          this.inbound.close.totals.palletsRestack += i.palletsRestack;
          this.inbound.close.totals.qcInspection += i.qcInspection;
          this.inbound.close.totals.damagedBoxes += i.damagedBoxes;
          this.inbound.close.totals.damagedPallets += i.damagedPallets;
          this.inbound.close.totals.unloadedPalletsQty += i.unloadedPalletsQty;
          this.inbound.close.totals.palletsRestackQty += i.palletsRestackQty;
          this.inbound.close.totals.qcInspectionQty += i.qcInspectionQty;
          this.inbound.close.totals.damagedBoxesQty += i.damagedBoxesQty;
          this.inbound.close.totals.damagedPalletsQty += i.damagedPalletsQty;

          this.inbound.close.totals.inbPalletsIn += i.inbPalletsIn;
          this.inbound.close.totals.inbPTI += i.inbPTI;
          this.inbound.close.totals.inbRepackIn += i.inbRepackIn;
          this.inbound.close.totals.inbPalletReplacement +=
            i.inbPalletReplacement;
          this.inbound.close.totals.inbOvertime += i.inbOvertime;
          this.inbound.close.totals.inbSundayOt += i.inbSundayOt;
          this.inbound.close.totals.inbCucumberRepack += i.inbCucumberRepack;
          this.inbound.close.totals.inbPLURepack += i.inbPLURepack;
          this.inbound.close.totals.inbSquashRepack += i.inbSquashRepack;
          this.inbound.close.totals.inbTomatoRepack += i.inbTomatoRepack;

          this.inbound.close.totals.inbPalletsInQty += i.inbPalletsInQty;
          this.inbound.close.totals.inbPTIQty += i.inbPTIQty;
          this.inbound.close.totals.inbRepackInQty += i.inbRepackInQty;
          this.inbound.close.totals.inbPalletReplacementQty +=
            i.inbPalletReplacementQty;
          this.inbound.close.totals.inbOvertimeQty += i.inbOvertimeQty;
          this.inbound.close.totals.inbSundayOtQty += i.inbSundayOtQty;
          this.inbound.close.totals.inbCucumberRepackQty +=
            i.inbCucumberRepackQty;
          this.inbound.close.totals.inbPLURepackQty += i.inbPLURepackQty;
          this.inbound.close.totals.inbSquashRepackQty += i.inbSquashRepackQty;
          this.inbound.close.totals.inbTomatoRepackQty += i.inbTomatoRepackQty;
        });

        this.inbound.close.isLoading = false;
        this.setLoading(false);
      } catch (err) {
        console.log(err);
        this.setLoading(false);
      }
    },
    async download() {
      this.setLoading(true);
      try {
        const response = await inbound.calculationCloseExport(
          this.controls.searchForm
        );
        const blob = new Blob([response.body]);
        const link = document.createElement("a");
        link.className = "file";
        link.href = window.URL.createObjectURL(blob);
        link.download = "CloseInbound.xlsm";
        document.body.appendChild(link);
        this.setLoading(false);
        link.click();
      } catch {
        this.setLoading(false);
      }
    },
    getAllCompanies(search) {
      companies.getCompanyCombo(search).then((res) => {
        this.controls.companies = res.body.data;
        this.controls.companies.unshift({
          idCompany: null,
          companyName: "ALL",
        });
      });
    },
    updateValues(values) {
      this.form.date.startDate = moment(values.startDate).format("YYYY-MM-DD");
      this.form.date.endDate = moment(values.endDate).format("YYYY-MM-DD");
    },
    checkOpen(open) {
      // console.log("event: open", open);
    },
    dateFormat(classes, date) {
      const dateToCompare = moment(date);
      let yesterday = moment().subtract(3, "day");
      //if (!classes.disabled) {
      //  classes.disabled = dateToCompare.isSame(yesterday, "day");
      //}
      return classes;
    },
  },
};
</script>

<style>
.reportrange-text {
  padding: 0px !important;
  border: none !important;
}
</style>
